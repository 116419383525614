import Vue from 'vue';
import { RequestState, RequestStateStatus } from '@/api/core';
import { PageMetaData, PageMetaItem } from '@/core/meta';
import { Enum } from '@/core/utils/enum';
import { HydrationHelper } from '@/store/plugins/hydration';
import { APISampleResponse } from './example';
import { ImageData } from './image';
import { LinkData, LinksList } from './link';
import { BaseType } from './base';

export const Authors = new Enum({
  Ajai_Kannan: 'ajai_kannan',
  Divya_Gurunath: 'divya_gurunath',
  Goutham: 'goutham',
  Nooreash: 'nooreash',
  Anto: 'anto',
  Arun: 'arun',
  Abhinaya: 'Abhinaya',
});

export class APIStatusCode {
  get statusClass() {
    let clsSuffix = '5xx';

    if (this.http_code < 200) {
      clsSuffix = '1xx';
    } else if (this.http_code < 300) {
      clsSuffix = '2xx';
    } else if (this.http_code < 400) {
      clsSuffix = '3xx';
    } else if (this.http_code < 500) {
      clsSuffix = '4xx';
    }

    return `status-${clsSuffix}`;
  }

  static pageDefault() {
    return [
      APIStatusCode.createFromJson({ http_code: 200, reason: 'OK.', desc: 'Everything is fine.' }),
      APIStatusCode.createFromJson({
        http_code: 500,
        code: 'UN-500-0',
        reason: 'Unknown Error.',
        desc: `In unpredictable circumstances the server may
        throw 500 errors for a very brief period.
        When this happens retry the same request.`,
      }),
    ];
  }

  static createFromJson(raw) {
    let data = new APIStatusCode();
    data = Object.assign(data, raw);
    if (raw.links) {
      data.links = raw.links.map((x) => LinkData.createFromJson(x));
    }
    return data;
  }
}

export class Page extends BaseType {
  constructor() {
    super();
    this.loaded = false;
    this.toc = [];
  }

  metaData() {
    const title = this.meta_title;
    const desc = this.meta_desc;
    const image = this.meta_image_url;

    const otherTags = {};
    if (this.meta_keywords) {
      otherTags.keywords = new PageMetaItem(this.meta_keywords);
    }
    return new PageMetaData(title, desc, { image, otherMetaTags: otherTags });
  }

  static typeName() {
    return 'Page';
  }
}

export const DeliveryMethods = new Enum({
  HTTPRest: 'http_rest',
  WebSocket: 'web_socket',
  WebHook: 'web_hook',
  GoogleFirebase: 'google:firebase',
  AmazonEventBridge: 'amazon:event_bridge',
});

export class APIDataDesc extends BaseType {
  static createFromJson(raw) {
    let data = new APIDataDesc();
    data = Object.assign(data, raw);
    if (raw.links) {
      data.links = raw.links.map((x) => LinkData.createFromJson(x));
    }
    if (raw.img) {
      data.img = ImageData.createFromJson(raw.img);
    }
    return data;
  }

  static typeName() {
    return 'APIDataDesc';
  }
}

export class APIDeliverMethod {
  static createFromJson(raw) {
    let data = new APIDeliverMethod();
    data = Object.assign(data, raw);
    data.method = DeliveryMethods.parse(raw.method);
    data.link = LinkData.createFromJson(raw.link);
    return data;
  }
}

export const PageSectionSupportedTypes = new Enum({
  DataDesc: 'DataDesc',
  DataDescList: 'DataDescList',
  APISampleCodeSection: 'APISampleCodeSection',
  APISampleResponse: 'APISampleResponse',
  LinksList: 'LinksList',
  StaticSection: 'StaticSection',
  ImageAsset: 'ImageAsset',
  GraphqlQuery: 'GraphqlQuery',
});

export const PageSectionTemplates = new Enum({
  SingleColumn: 'single-column',
  DualColumn: 'dual-column',
  SubSection: 'sub-section',
  ErrorCodeSection: 'error-code-section',
  WrapTextAround: 'wrap-text-around',
  OtherSports: 'other-sports-section',
  UseCases: 'use-case-section',
  News: 'news-section',
  Others: 'others',
});

export const StaticSectionTypes = new Enum({
  SupportSection: 'SupportSection',
  APIEndpoints: 'APIEndpoints',
  SeniorMenu: 'SeniorMenu',
  RelatedAPIs: 'RelatedAPIs',
  CricketPlans: 'CricketPlans',
  AlexaSection: 'AlexaSection',
  GetStartedSection: 'GetStartedSection',
});

export const Currencies = new Enum({
  INR: 'INR',
  USD: 'USD',
  EUR: 'EUR',
});

export const countries = {
  usd: 'International',
  eur: 'Europe',
  inr: 'India',
};

export const minAmountForDiscount = {
  usd: '69',
  eur: '59',
  inr: '5000',
};

export const currencySymbols = {
  inr: '₹',
  usd: '$',
  eur: '€',
};

export const CurrencyObjs = new Enum({
  INR: {
    key: 'INR',
    symbol: '₹',
  },
  USD: {
    key: 'USD',
    symbol: '$',
  },
  EUR: {
    key: 'EUR',
    symbol: '€',
  },
});

export const supportNumbers = {
  usTollfree: {
    label: '+1 (855) 2SPORT4',
    link: 'tel:+18552776784',
  },
  indianNumber: {
    label: '+91 90030 21362',
    link: 'tel:+919003021362',
  },
  indianNumberSales: {
    label: 'Sales: +91 90030 21362',
    link: 'tel:+919003021362',
  },
  supportMail: {
    label: 'support@roanuz.com',
    link: 'mailto:support@roanuz.com',
  },
};

export class PageSectionContent {
  static createFromJson(raw) {
    let data = new PageSectionContent();
    data = Object.assign(data, raw);
    data.doc_type = PageSectionSupportedTypes.parse(raw.doc_type);
    if (raw.static_section_id && data.doc_type.isStaticSection) {
      data.static_section_id = StaticSectionTypes.parse(raw.static_section_id);
    }
    data.template_id = raw.template_id ? PageSectionTemplates.parse(raw.template_id) : PageSectionTemplates.Others;
    return data;
  }
}

export class DataDescList {
  static createFromJson(raw, relatedDocs = null) {
    const ddList = new DataDescList();
    ddList.items = [];

    if (raw.items) {
      ddList.items = raw.items.map((r) => APIDataDesc.createFromJson(r));
    } else if (raw.items_keys) {
      ddList.items = raw.items_keys.map((r) => APIDataDesc.createFromJson(relatedDocs[r]));
    }
    if (raw.desc) {
      ddList.desc = raw.desc;
    }
    return ddList;
  }
}

export class WebPage extends Page {
  static typeName() {
    return 'WebPage';
  }

  static createFromJson(raw, relatedDocs = null) {
    let data = new WebPage();
    data = Object.assign(data, raw);
    data.loaded = true;
    data.detail = null;
    data.responses = {};

    if (data.img) {
      data.img = ImageData.createFromJson(data.img);
    }

    if (data.sections && data.sections.length > 0) {
      Object.keys(data.sections).forEach((key) => {
        const docKey = data.sections[key].doc_key;
        const docType = data.sections[key].doc_type;
        data.sections[key] = PageSectionContent.createFromJson(data.sections[key]);
        if (relatedDocs && relatedDocs[docType]) {
          const docDetail = relatedDocs[docType][docKey];
          if (docDetail && docDetail.img && data.sections[key].doc_type.isImageAsset) {
            docDetail.img = ImageData.createFromJson(docDetail.img);
          }
          if (docDetail) {
            if (data.sections[key].doc_type.isDataDesc) {
              data.sections[key].detail = APIDataDesc.createFromJson(docDetail);
            } else if (data.sections[key].doc_type.isDataDescList) {
              data.sections[key].detail = DataDescList.createFromJson(
                docDetail,
                relatedDocs[PageSectionSupportedTypes.DataDesc.value],
              );
            } else if (data.sections[key].doc_type.isLinksList) {
              data.sections[key].detail = LinksList.createFromJson(docDetail);
            } else {
              data.sections[key].detail = docDetail;
            }
          }
        } else if (data.sections[key].doc_type.isImageAsset) {
          data.sections[key].detail.img = ImageData.createFromJson(data.sections[key].detail.img);
        }
      });
    }

    return data;
  }

  parseDetails(raw) {
    Object.keys(raw).forEach((key) => {
      raw[key] = PageSectionContent.createFromJson(raw[key]);
      if (raw[key].doc_type.isDataDesc) {
        raw[key].detail = APIDataDesc.createFromJson(raw[key].detail);
      } else if (raw[key].doc_type.isLinksList) {
        raw[key].detail = LinksList.createFromJson(raw[key].detail);
      }
    });
    return raw;
  }

  fetchDetail(apiInstance, store) {
    const params = { route: this.route, route_value: this.route_value };
    return apiInstance
      .get('/endpoint/webpage/detail/', { params })
      .then((resp) => {
        const { detail } = resp.data.data;
        store.commit('page/updateActivePageDetail', { detail });
        return detail;
      });
  }

  static getPricing(apiInstance, data) {
    return apiInstance
      .get('/business/cricket/resources/?sport=cricket', { data })
      .then((resp) => resp.data.data);
  }

  static postContactDetails(apiInstance, data) {
    return apiInstance
      .post('business/sales/leads/requests/', data)
      .then((resp) => resp).catch((err) => {
        console.log('err', err);
      });
  }

  static fetch(apiInstance, inMemoryCache, route, routeValue) {
    const params = { route, route_value: routeValue };
    const cacheKey = `webpage_${route}_${routeValue}`;
    if (inMemoryCache && inMemoryCache[cacheKey]) {
      return Promise.resolve(inMemoryCache[cacheKey]);
    }

    return apiInstance
      .get('/endpoint/webpage/', { params })
      .then((resp) => {
        const data = WebPage.createFromJson(resp.data.data.page, resp.data.data.related_docs);
        if (inMemoryCache) {
          inMemoryCache[cacheKey] = data;
        }
        return data;
      });
  }
}

export class APIPage extends Page {
  static typeName() {
    return 'APIPage';
  }

  get hasMoreDeliveryMethods() {
    return this.supported_delivery_methods.length > 1;
  }

  get allStatusCodes() {
    const codes = [];

    if (this.detail && this.detail.status_codes) {
      codes.push(...this.detail.status_codes);
    }

    codes.push(...APIStatusCode.pageDefault());

    return codes;
  }

  parseDetails(raw) {
    if (raw.status_codes) {
      raw.status_codes = raw.status_codes.map((r) => APIStatusCode.createFromJson(r));
    }

    raw.scenarios = APIDataDesc.createFromJson(raw.scenarios);
    raw.explores = APIDataDesc.createFromJson(raw.explores);
    raw.downloads = APIDataDesc.createFromJson(raw.downloads);

    return raw;
  }

  buildTOC() {
    this.toc = [
      { name: 'Request structure', hash: 'goto-req-struct' },
    ];

    if (this.sample_codes && this.sample_codes.length) {
      this.toc.push({ name: 'Sample Code', hash: 'goto-sample-code-0' });
    }

    if (this.scenarios_title) {
      this.toc.push({ name: this.scenarios_title, hash: 'goto-scenarios' });
    }

    if (this.explores_title) {
      this.toc.push({ name: this.explores_title, hash: 'goto-explores' });
    }

    this.toc.push({ name: 'HTTP Status', hash: 'goto-http-status' });
    this.toc.push({ name: 'Cache', hash: 'goto-cache' });

    if (this.downloads_title) {
      this.toc.push({ name: this.downloads_title, hash: 'goto-downloads' });
    }

    this.toc.push({ name: 'Response Schema', hash: 'goto-res-schema' });
  }

  static createFromJson(raw) {
    let data = new APIPage();
    data = Object.assign(data, raw);
    data.loaded = true;
    data.detail = null;
    data.responses = {};

    if (data.img) {
      data.img = ImageData.createFromJson(data.img);
    }

    if (raw.delivery_method) {
      data.delivery_method = DeliveryMethods.parse(raw.delivery_method);
    } else {
      data.delivery_method = DeliveryMethods.HTTPRest;
    }

    if (!raw.http_desc) {
      data.http_desc = 'Possible status code you may receive in response to your request.';
    }

    if (raw.related_apis) {
      data.related_apis = raw.related_apis.map((x) => LinkData.createFromJson(x));
    }

    if (raw.supported_delivery_methods) {
      data.supported_delivery_methods = raw.supported_delivery_methods.map((x) => APIDeliverMethod.createFromJson(x));
    } else {
      data.supported_delivery_methods = [];
    }

    data.buildTOC();

    return data;
  }

  fetchDetail(apiInstance, store) {
    const params = { route: this.route, route_value: this.route_value };
    return apiInstance
      .get('/endpoint/apidocs/page/detail/', { params })
      .then((resp) => {
        const { detail } = resp.data.data;
        store.commit('page/updateActivePageDetail', { detail });
        return detail;
      });
  }

  fetchResponses(apiInstance, store) {
    const params = { route: this.route, route_value: this.route_value };
    return apiInstance
      .get('/endpoint/apidocs/page/responses/', { params })
      .then((resp) => {
        const { responses } = resp.data.data;
        Object.keys(responses).forEach((key) => {
          responses[key] = APISampleResponse.createFromJson(responses[key]);
        });
        store.commit('page/updateActivePageResponses', { responses });
        return responses;
      });
  }

  static fetch(apiInstance, inMemoryCache, route, routeValue) {
    const params = { route, route_value: routeValue };
    const cacheKey = `apidocs_page_${route}_${routeValue}`;
    if (inMemoryCache && inMemoryCache[cacheKey]) {
      return Promise.resolve(inMemoryCache[cacheKey]);
    }

    return apiInstance
      .get('/endpoint/apidocs/page/', { params })
      .then((resp) => {
        const data = APIPage.createFromJson(resp.data.data.page);
        if (inMemoryCache) {
          inMemoryCache[cacheKey] = data;
        }
        return data;
      });
  }
}

export class Blog extends WebPage {
  constructor() {
    super();
    this.loaded = false;
  }

  static createFromJson(raw) {
    let data = new Blog();
    data = Object.assign(data, raw);
    return data;
  }

  static typeName() {
    return 'Blog';
  }

  static fetchBlog(apiInstance, inMemoryCache, route, routeValue) {
    const params = { route, route_value: routeValue, _usefresh: 123 };
    const cacheKey = `blogpage_${routeValue}`;
    if (inMemoryCache && inMemoryCache[cacheKey]) {
      return Promise.resolve(inMemoryCache[cacheKey]);
    }

    return apiInstance
      .get('/endpoint/blog/page/', { params })
      .then((resp) => {
        const data = WebPage.createFromJson(resp.data.data.blog, resp.data.data.related_docs);
        data.author_keys.forEach((author, index) => {
          data.author_keys[index] = Authors.parse(author);
        });
        if (inMemoryCache) {
          inMemoryCache[cacheKey] = data;
        }
        return data;
      });
  }
}

export class Article extends WebPage {
  constructor() {
    super();
    this.loaded = false;
  }

  static createFromJson(raw) {
    let data = new Article();
    data = Object.assign(data, raw);
    return data;
  }

  static typeName() {
    return 'Article';
  }

  static fetchArticle(apiInstance, inMemoryCache, route, routeValue, projectKey) {
    const params = {
      route,
      route_value: routeValue,
      proj_key: projectKey,
      _usefresh: 123,
    };
    const cacheKey = `articlepage_${routeValue}`;
    if (inMemoryCache && inMemoryCache[cacheKey]) {
      return Promise.resolve(inMemoryCache[cacheKey]);
    }

    return apiInstance
      .get('/endpoint/article/page/', { params })
      .then((resp) => {
        const data = WebPage.createFromJson(resp.data.data.article, resp.data.data.related_docs);
        data.author_keys.forEach((author, index) => {
          data.author_keys[index] = Authors.parse(author);
        });
        if (inMemoryCache) {
          inMemoryCache[cacheKey] = data;
        }
        return data;
      });
  }
}

export class GraphqlPage extends Page {
  static typeName() {
    return 'GraphqlPage';
  }

  get hasMoreDeliveryMethods() {
    return this.supported_delivery_methods.length > 1;
  }

  get allStatusCodes() {
    const codes = [];

    if (this.detail && this.detail.status_codes) {
      codes.push(...this.detail.status_codes);
    }

    codes.push(...APIStatusCode.pageDefault());

    return codes;
  }

  parseDetails(raw) {
    if (raw.status_codes) {
      raw.status_codes = raw.status_codes.map((r) => APIStatusCode.createFromJson(r));
    }

    raw.scenarios = APIDataDesc.createFromJson(raw.scenarios);
    raw.explores = APIDataDesc.createFromJson(raw.explores);
    raw.downloads = APIDataDesc.createFromJson(raw.downloads);

    return raw;
  }

  buildTOC() {
    this.toc = [];
    if (this.getting_started) {
      this.toc.push({ name: 'Getting started', hash: 'goto-getting-started' });
    }
    if (this.graphql_sample_queries && this.graphql_sample_queries.length) {
      this.toc.push({ name: 'Sample Query', hash: 'goto-sample-code' });
    }
    if (this.query_inputs && this.query_inputs.length) {
      this.toc.push({ name: 'Query Arguments', hash: 'goto-query-inputs' });
    }
    if (this.graphql_sample_queries && this.graphql_sample_queries.length > 1) {
      this.toc.push({ name: 'More Scenarios', hash: 'goto-more-scenarios' });
    }
    if (this.status_codes && this.status_codes.length) {
      this.toc.push({ name: 'HTTP Extentions', hash: 'goto-http-status' });
    }
    if (this.downloads) {
      this.toc.push({ name: 'downloads', hash: 'goto-downloads' });
    }
  }

  static createFromJson(raw) {
    let data = new GraphqlPage();
    data = Object.assign(data, raw);
    data.loaded = true;
    data.detail = null;
    data.responses = {};

    if (data.img) {
      data.img = ImageData.createFromJson(data.img);
    }

    if (raw.delivery_method) {
      data.delivery_method = DeliveryMethods.parse(raw.delivery_method);
    } else {
      data.delivery_method = DeliveryMethods.HTTPRest;
    }

    if (!raw.http_desc) {
      data.http_desc = 'Possible status code you may receive in response to your request.';
    }

    if (raw.related_apis) {
      data.related_apis = raw.related_apis.map((x) => LinkData.createFromJson(x));
    }

    if (raw.supported_delivery_methods) {
      data.supported_delivery_methods = raw.supported_delivery_methods.map((x) => APIDeliverMethod.createFromJson(x));
    } else {
      data.supported_delivery_methods = [];
    }

    data.buildTOC();

    return data;
  }

  fetchDetail(apiInstance, store) {
    const params = { route: this.route, route_value: this.route_value };
    return apiInstance
      .get('/endpoint/graphqldocs/page/detail/', { params })
      .then((resp) => {
        const { detail } = resp.data.data;
        store.commit('page/updateActivePageDetail', { detail });
        return detail;
      });
  }

  fetchResponses(apiInstance, store) {
    const params = { route: this.route, route_value: this.route_value };
    return apiInstance
      .get('/endpoint/graphqldocs/page/responses/', { params })
      .then((resp) => {
        const { responses } = resp.data.data;
        Object.keys(responses).forEach((key) => {
          responses[key] = APISampleResponse.createFromJson(responses[key]);
        });

        store.commit('page/updateActivePageResponses', { responses });
        return responses;
      });
  }

  static fetch(apiInstance, inMemoryCache, route, routeValue) {
    const params = { route, route_value: routeValue };
    const cacheKey = `graphqldocs_page_${route}_${routeValue}`;
    if (inMemoryCache && inMemoryCache[cacheKey]) {
      return Promise.resolve(inMemoryCache[cacheKey]);
    }

    return apiInstance
      .get('/endpoint/graphqldocs/page/detail/', { params })
      .then((resp) => {
        const data = GraphqlPage.createFromJson(resp.data.data.page);
        if (inMemoryCache) {
          inMemoryCache[cacheKey] = data;
        }
        return data;
      });
  }
}
HydrationHelper.registerType(Page);
HydrationHelper.registerType(WebPage);
HydrationHelper.registerType(APIPage);
HydrationHelper.registerType(GraphqlPage);
HydrationHelper.registerType(Blog);
HydrationHelper.registerType(Article);
HydrationHelper.registerType(APIDataDesc);

const initState = () => ({
  activePage: new Page(),
  activePageState: new RequestState(),
});

const getters = {};

const actions = {
  fetchActiveAPI({ commit, rootState }, { route, routeValue }) {
    commit('updateActivePageStatus', { status: RequestStateStatus.Loading });

    return APIPage.fetch(rootState.apiInstance, rootState.inMemoryCache, route, routeValue).then((data) => {
      commit('updateActivePage', { data });
      commit('updateActivePageStatus', { status: RequestStateStatus.Loaded });
    }).catch((err) => {
      // console.error(err);
      console.log('Error while fetching page ', route, routeValue);
      commit('updateActivePageStatus', { status: RequestStateStatus.Error, msg: err });
    });
  },

  fetchActivePage({ commit, rootState }, { route, routeValue }) {
    commit('updateActivePageStatus', { status: RequestStateStatus.Loading });

    return WebPage.fetch(rootState.apiInstance, rootState.inMemoryCache, route, routeValue).then((data) => {
      commit('updateActivePage', { data });
      commit('updateActivePageStatus', { status: RequestStateStatus.Loaded });
    }).catch((err) => {
      // console.error(err);
      console.log('Error while fetching page ', route, routeValue);
      commit('updateActivePageStatus', { status: RequestStateStatus.Error, msg: err });
    });
  },

  fetchBlogDetail({ commit, rootState }, { route, routeValue }) {
    commit('updateActivePageStatus', { status: RequestStateStatus.Loading });
    return Blog.fetchBlog(rootState.apiInstance, rootState.inMemoryCache, route, routeValue).then((data) => {
      commit('updateActivePage', { data });
      commit('updateActivePageStatus', { status: RequestStateStatus.Loaded });
    }).catch((err) => {
      console.log('Error while fetching page ', route, routeValue);
      commit('updateActivePageStatus', { status: RequestStateStatus.Error, msg: err });
    });
  },

  fetchArticleDetail({ commit, rootState }, { route, routeValue, projectKey }) {
    commit('updateActivePageStatus', { status: RequestStateStatus.Loading });
    return Article.fetchArticle(rootState.apiInstance,
      rootState.inMemoryCache, route, routeValue, projectKey).then((data) => {
      commit('updateActivePage', { data });
      commit('updateActivePageStatus', { status: RequestStateStatus.Loaded });
    }).catch((err) => {
      console.log('Error while fetching page ', route, routeValue, projectKey);
      commit('updateActivePageStatus', { status: RequestStateStatus.Error, msg: err });
    });
  },

  fetchGraphqlActiveAPI({ commit, rootState }, { route, routeValue }) {
    commit('updateActivePageStatus', { status: RequestStateStatus.Loading });

    return GraphqlPage.fetch(rootState.apiInstance, rootState.inMemoryCache, route, routeValue).then((data) => {
      commit('updateActivePage', { data });
      commit('updateActivePageStatus', { status: RequestStateStatus.Loaded });
    }).catch((err) => {
      // console.error(err);
      console.log('Error while fetching page ', route, routeValue);
      commit('updateActivePageStatus', { status: RequestStateStatus.Error, msg: err });
    });
  },
};

const mutations = {
  updateActivePage(state, { data }) {
    Vue.set(state, 'activePage', data);
  },

  resetActivePage(state) {
    Vue.set(state, 'activePage', new Page());
    Vue.set(state, 'activePageState', new RequestState());
  },

  updateActivePageStatus(state, { status, msg }) {
    state.activePageState.status = status;
    state.activePageState.ref = msg;
  },

  updateActivePageDetail(state, { detail }) {
    state.activePage.detail = state.activePage.parseDetails(detail);
  },

  updateActivePageResponses(state, { responses }) {
    state.activePage.responses = responses;
  },
};

export default {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
};
